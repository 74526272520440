

import {Component, Prop, Vue} from 'vue-property-decorator'
import {store} from '@/main';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import PatternUtils from '@/utils/PatternUtils';

@Component({})
export default class PwdDef extends Vue {
  @Prop({ type: Number, default: 8 }) private minlength!: number | undefined;

  @Prop({ type: String, default: 'huit' }) private label!: string | undefined;

  private minStrengthMdp: number = 3;
  private password1: ISigInputTextDto =
      {value: '', hasError: true, msgError: ''};
  private password2: ISigInputTextDto = {value: '', hasError: true, msgError: ''};
  private samePassword: boolean = false;
  private strengthPwd: number = 0;
  private specialCharacteres: string = '';

  private containsProhibitedWords: boolean = false;

  // Type de l'input du password
  private typep: string = 'password';

  private passwordConfirmNotValid: boolean = false;

  /**
   * Méthode effaçant les des MDP.
   */
  public erasePassword(): void {
    this.password1.value = '';
    this.password1.hasError = true;
    this.password1.msgError = '';
    this.password2.value = '';
    this.password2.hasError = true;
    this.password2.msgError = '';
    this.strengthPwd = 0;
    this.eraseStrengthColor();
  }

  public initPassword1(val: string): void {
    this.password1.value = val;
  }

  /**
   * Méthode indiquant la validité du composant
   * (accessible depuis le parent par this.$refs.pwd.validityPassword où pwd
   * est définit lors de son injection dans le parent)
   */
  public get validityPassword(): boolean {
    const force: number = PatternUtils.getColorAndStrengthLimited(this.strengthPwd).idx;
    return !this.containsProhibitedWords && this.samePassword && (force >= this.minStrengthMdp);
  }

  public checkProhibitedWords(): void {
    this.containsProhibitedWords = false;
    const prohibitedWords: string [] = store.getters.prohibitedWords;
    prohibitedWords.forEach((pro) => {
      if (this.password1.value.toLowerCase().includes(pro.toLowerCase())) {
        this.containsProhibitedWords = true;
      }
    });
  }

  /**
   * Méthode retournant le mdp
   */
  public get newPassword(): string {
    return this.password1.value;
  }

  /**
   * Génère un mot de passe aléatoire.
   */
  private generatePassword(): void {
    const mdp = PatternUtils.generateRandomPassword(this.specialCharacteres);
    this.password1.value = mdp;
    this.password2.value = mdp;
    this.onPassword1Changed();
    this.onPassword2Changed();
  }

  private onPassword1Changed() {
    const value = this.password1.value;
    this.checkProhibitedWords();
    this.samePassword = this.password2.value === value;
    if (!this.samePassword && this.password2.value.length > 0) {
      this.passwordConfirmNotValid = !this.samePassword;
      this.password2.msgError = 'Votre confirmation doit être identique au mot de passe.';
      this.password2.hasError = true;
    } else if (this.samePassword && this.password2.value.length > 0) {
      this.passwordConfirmNotValid = !this.samePassword;
      this.password2.msgError = '';
      this.password2.hasError = false;
    }
    this.strengthPwd = PatternUtils.mesureStrength(value, this.specialCharacteres);
    this.updateIhmPwdStrengthColor();
    // validation
    this.validPassword1(value);
    this.$emit('validityWatch', this.validityPassword);
  }

  /**
   * Mise à jour de l'affichage du module sur la fiabilité du mdp.
   */
  private updateIhmPwdStrengthColor() {
    // RAZ des couleurs
    this.eraseStrengthColor();
    // Mettre les bonnes couleurs
    const str: any = PatternUtils.getColorAndStrengthLimited(this.strengthPwd);
    const idx: number = str.idx;
    const color: string = str.col;
    for (let i = 0; i < idx; i++) {
      const liElement: HTMLBaseElement = this.$refs['item' + i] as HTMLBaseElement;
      liElement.style.background = color;
    }
  }

  /**
   * RAZ des couleurs
   */
  private eraseStrengthColor() {
    for (let i = 0; i < 5; i++) {
      const liElement: HTMLBaseElement = this.$refs['item' + i] as HTMLBaseElement;
      liElement.style.background = '#DDD';
    }
  }

  private validPassword1(value: string) {
    if (value.trim().length === 0) {
      this.password1.msgError = 'Votre mot de passe est requis.';
      this.password1.hasError = true;
    } else if (this.containsProhibitedWords) {
      this.password1.msgError = 'Votre mot de passe ne doit contenir aucune des valeurs suivantes : [' +
          store.getters.prohibitedWords + ']';
      this.password1.hasError = true;
    } else if (this.minlength !== undefined && value.trim().length < this.minlength) {
      this.password1.msgError = 'Votre mot de passe doit comporter au moins ' + this.minlength  + ' caractères.';
      this.password1.hasError = true;
    } else if (new RegExp('[^a-zA-Z0-9' + PatternUtils.escapeRegExp(this.specialCharacteres) + ']').test(value)) {
      this.password1.msgError = 'Les seuls caractères autorisés sont : ' +
          '                     - Les minuscules et majuscules non accentuées' +
          '                     - Les chiffres de 0 à 9' +
          '                     - Les caractères spéciaux : ' + this.specialCharacteres;
      this.password1.hasError = true;
    } else if (!PatternUtils.generateRegexValidityPwd(this.specialCharacteres).test(value.trim())) {
      this.password1.msgError = 'Votre mot de passe doit comporter des caractères parmi des lettres (minuscules et majuscules), des\n' +
          'chiffres et des caractères spéciaux.';
      this.password1.hasError = true;
    } else {
      this.password1.msgError = '';
      this.password1.hasError = false;
    }
  }

  private onPassword2Changed() {
    const value = this.password2.value;
    this.samePassword = this.password1.value === value;
    this.passwordConfirmNotValid = !this.samePassword;
    if (value.length === 0) {
      this.password2.msgError = 'La confirmation du mot de passe est requise.';
      this.password2.hasError = true;
    } else if (this.passwordConfirmNotValid) {
      this.password2.msgError = 'Votre confirmation doit être identique au mot de passe.';
      this.password2.hasError = true;
    } else {
      this.password2.msgError = '';
      this.password2.hasError = false;
    }
    this.$emit('validityWatch', this.validityPassword);
  }


  /**
   * Methode lors de la cration du composant.
   */
  private created() {
      this.specialCharacteres = store.getters.pwdSpecialCharacters;
  }

  /**
   *  Voir le mot de passe.
   */
  private hovered(): void {
    this.typep = 'text';
  }

  /**
   * Cacher le mot de passe.
   */
  private holeave(): void {
    this.typep = 'password';
  }

}
