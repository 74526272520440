
import {Component, Ref, Vue} from 'vue-property-decorator';
import {services, store} from '@/main';
import {IAccountValidationRequest, IPersonnalInfo, ITokenResponse} from '@/services/dto/IDtos';
import {ROLE} from '@/services/dto/Roles';
import Password from '@/components/personnal-info/PwdDef.vue';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';
import PatternUtils from '@/utils/PatternUtils';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import SigSelect from '@/components/user/SigSelect.vue';
import AlertService from '@/utils/AlertService';
import ErrorUtils from '@/utils/ErrorUtils';
import UserUtils from '@/utils/UserUtils';

@Component({
	components: {
    SigSelect,
    SigInputTexte,
		Password,
	},
})
export default class FirstConnexion extends Vue {
  private patternUtils = PatternUtils;
  // Champs référence
  @Ref('refPoste') private refPostSelection!: SigSelect;
  @Ref('refMobilePhone') private refMobilePhone!: SigInputTexte;
  @Ref('refRescueMail') private refRescueMail!: SigInputTexte;
  @Ref('pwd') private refPassword!: Password;


	private login: string = this.$route.params.login;
	private activationCode: string = this.$route.params.activationCode;
	private tokenValid: boolean = false;
	private tokenValidNotFound: boolean = false;
	private isCollab: boolean = true;
  private isNotEntity: boolean = true;

  // Validation
  private passwordValid: boolean = false;

  private selectPosteOptions = UserUtils.allUserFonctionForSelectOptions;

  private tokenResponse: ITokenResponse = {} as ITokenResponse;

  private watchPasswordValidity(val: boolean) {
    this.passwordValid = val;
  }

	private created() {
		this.loadTokenAndProhibitedWords();
	}

  private loadTokenAndProhibitedWords() {
    services.restService.callGet('/api/v1/tokens/' + this.activationCode + '/' + this.login + '/check')
        .then((res) => {
          if (res.request.status === 404) {
            // On affiche que le token n'est plus valide
            this.tokenValid = false;
          } else {
            // On affiche le formulaire de saisie des informations.
            this.tokenValid = true;
            // On charge les données depuis le back
            this.tokenResponse = res.data;

            this.isCollab = res.data.roleUser === 'ROLE_SELFCARE_COLLABORATEUR';
            this.isNotEntity = (res.data.roleUser === 'ROLE_SELFCARE_COLLABORATEUR' || res.data.roleUser === 'ROLE_SELFCARE_NOTAIRE');

            // On charge les mots interdits dans les mdp
            const promiseProhibited: Promise<any> = services.restService
                .callGet('/api/v1/passwords/' + this.tokenResponse.accountId + '/prohibited-words');
            promiseProhibited.then(
                (d) => {
                  store.commit('loadProhibitedWords', d.data);
                },
            );
          }
        })
        .catch(() => {
          this.tokenValidNotFound = true;
          this.tokenValid = false;
        });
  }

	/**
	 * Affiche la fonction de l'utilisateur qui crée son profil
	 */
	private get displayRoleLabel(): string {
		const role: ROLE = this.tokenResponse.roleUser;
		return DisplayEnumUtils.displayRole(role);
	}

  /**
   * Active/Désactive le bouton Valider.
   */
  private notValideForm(): boolean {
    const notValidChamps: boolean[] = [];

    // Validation du téléphone portable
    notValidChamps.push(this.refMobilePhone !== undefined && this.refMobilePhone.val.hasError);

    // Poste est vide alors que c'est un collab
    // res = res && (this.poste !== '' || !this.isCollab);
    notValidChamps.push(this.refPostSelection !== undefined && this.refPostSelection.val.hasError);
    // Le mod de passe n'est valide

    // Rescue validation
    notValidChamps.push(this.refRescueMail !== undefined && this.refRescueMail.val.hasError);

    // Validation du mot de passe
    notValidChamps.push(!this.passwordValid);
    // Attention à la négation
    return notValidChamps.some((x) => x);
  }

  private saveAll() {
    const rescueMail: string = this.refRescueMail.val.value;
    // Vérification back de la validité du mail de secours
    services.restService.callGet('/api/v1/availability/rescue-email/' + rescueMail +
        '/' + this.tokenResponse.accountId)
        .then((res) => {
          if (res.status === 200) {
            // On appelle la création effective du profil
            const accountValidation: IAccountValidationRequest = this.getDtoSaveForm();

            // Activation et connexion de l'utilisateur
            const promiseCreate: Promise<any> = services.restService
                .callPost('/api/v1/connected-user/current/activate', accountValidation);
            promiseCreate.then((d) => {
              if (typeof d !== 'undefined' && d.status === 201) { // Creation avec succès
                // On redirige vers la page des info personnelles (cas particulier car on le met dans le store et
                // services.loginService.loadUserPostProcessing se charge d'y aller)
                store.commit('saveTargetUrl', '/account/personal-info/personnal-info');
                // Charge les informations sur l'utilisateur courant.
                services.restService.callGet('/api/v1/connected-user/current')
                    .then(this.loadUserPostProcessing);
              }
            });
            promiseCreate.catch((respError) => {
              // Si le SIG revoie une erreur affichage
              ErrorUtils.showErrors(respError.data);
              switch (respError.status) {
                case 400:
                  ErrorUtils.defaultCallback(respError, 'Erreur : ');
                  break;
                case 404:
                  // Affichage d'un message d'erreur
                  AlertService.error('Impossible d\'accéder au service d\'activation de compte, contactez votre support.');
                  break;
                default:
                  ErrorUtils.showErrors(respError.data, 'Une erreur s\'est produite');
              }
            });
          }
        })
        .catch((respError) => {
          if (respError.status === 409) {
            this.refRescueMail.error('Cette adresse de secours est déjà utilisée par un autre utilisateur');
            // Afficher le message "Cette adresse de secours est déjà utilisée par un autre utilisateur".
          } else {
            console.error(respError);
            // Affichage des validation exceptions
          }
        });
  }

  private loadUserPostProcessing(res: any): void {
    services.loginService.loadUserPostProcessing(res);
    let message = '';
    // Création d'une message box de succès en fonction du role
    if (!this.isNotEntity) {
      message = 'Vos informations personnelles ont bien été enregistrées. Votre compte Mon Espace Real est activé. Pensez également à informer les collaborateurs utilisant ce même compte Mon Espace Real.';
    } else {
      message = 'Vos informations personnelles ont bien été enregistrées. Votre compte Mon Espace Real est activé.';
    }
    AlertService.success(message, 'OK');
  }

  private getDtoSaveForm(): IAccountValidationRequest {
    const accountValidation: IAccountValidationRequest = {} as IAccountValidationRequest;
    const personnalInfo: IPersonnalInfo = {} as IPersonnalInfo;
    if (this.refPostSelection !== undefined && this.refPostSelection.val.value !== '') {
      personnalInfo.poste = this.refPostSelection.val.value;
    }
    if (this.refMobilePhone !== undefined && this.refMobilePhone.val.value !== '') {
      personnalInfo.telephone = this.refMobilePhone.val.value;
    }
    personnalInfo.rescueMail = this.refRescueMail.val.value;
    accountValidation.password = this.refPassword.newPassword;
    accountValidation.login = this.login;
    accountValidation.tokenId = this.activationCode;
    accountValidation.personalInfosDto = personnalInfo;
    return accountValidation;
  }


}
